import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore"; // Import Firestore
import { getStorage } from "firebase/storage"; // Import Storage
import { getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBh1_saInyvZCu-NpA2ncB-H-Jt3O8oQZk",
  authDomain: "businesscardsbw.firebaseapp.com",
  projectId: "businesscardsbw",
  storageBucket: "businesscardsbw.appspot.com",
  messagingSenderId: "477316693107",
  appId: "1:477316693107:web:97de774116eb5574c83e06",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app); // Initialize Firestore
const storage = getStorage(app); // Initialize Storage
const functions = getFunctions(app); // Initialize functions

export { auth, db, storage, functions }; // Export the services
