import Default from "./default";
import Elegant from "./elegant";
// import Modern from "./modern";
// import Classic from "./classic";

const layouts = {
  default: Default,
  elegant: Elegant,
  //   modern: Modern,
  //   classic: Classic,
};

export const getLayout = (layoutType) => layouts[layoutType] || Default;
