import React from "react";
import { useParams } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { Container } from "@mui/material";
import Dashboard from "./dashboard";
import { getCompanyTheme } from "../../themes/theme"; // Theme generation function
import companies from "../../configs/companies"; // Company configurations

const DashboardWrapper = () => {
  const { companyName } = useParams(); // Extract company name from URL
  const companyConfig = companies[companyName] || companies.default; // Get company config or use default
  const theme = getCompanyTheme(companyConfig); // Generate theme dynamically

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="md">
        <Dashboard companyName={companyName} />
      </Container>
    </ThemeProvider>
  );
};

export default DashboardWrapper;
