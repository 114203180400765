import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { CssBaseline, Container } from "@mui/material";
import Login from "./components/auth/login";
import DashboardWrapper from "./components/dashboard/dashboardWrapper"; // Import DashboardWrapper
import BusinessCardDisplay from "./components/dashboard/businessCardDisplay";
import NotFound from "./components/notfound";

const App = () => {
  return (
    <Router>
      <CssBaseline />
      <Routes>
        {/* Public route for displaying the business card (QR code landing page) */}
        <Route
          path="/:companyName/business-card/:id"
          element={<BusinessCardDisplay />}
        />

        {/* Login page */}
        <Route
          path="/:companyName"
          element={
            <Container maxWidth="xs">
              <Login />
            </Container>
          }
        />

        {/* Protected dashboard route wrapped with DashboardWrapper */}
        <Route path="/:companyName/dashboard" element={<DashboardWrapper />} />

        {/* 404 Not Found route */}
        <Route
          path="*"
          element={
            <Container maxWidth="sm">
              <NotFound />
            </Container>
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
