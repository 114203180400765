import React from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@mui/material";
import { doc, deleteDoc } from "firebase/firestore";
import { db } from "../../configs/firebaseConfig";

const DeleteBusinessCardDialog = ({ open, onClose, cardId }) => {
  const handleDelete = async () => {
    try {
      const cardRef = doc(db, "businessCards", cardId);
      await deleteDoc(cardRef);
      onClose(true); // Pass "true" to indicate success
    } catch (error) {
      console.error("Error deleting business card:", error.message);
      onClose(false); // Pass "false" to indicate failure
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>
        Are you sure you want to delete this business card?
      </DialogTitle>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleDelete} variant="contained" color="error">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteBusinessCardDialog;
