import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../configs/firebaseConfig";
import { CircularProgress, Box, Typography } from "@mui/material";
import companies from "../../configs/companies";
import { getLayout } from "../layouts";

const BusinessCardDisplay = () => {
  const { companyName, id } = useParams(); // Get the company name and card ID from the URL
  const [businessCard, setBusinessCard] = useState(null);
  const [loading, setLoading] = useState(true);

  const companyConfig = companies[companyName] || companies.default; // Get company-specific settings
  const Layout = getLayout(companyConfig.layout); // Dynamically select the layout

  useEffect(() => {
    const fetchBusinessCard = async () => {
      try {
        const docRef = doc(db, "businessCards", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBusinessCard(docSnap.data());
        } else {
          console.error("No such document!");
        }
      } catch (error) {
        console.error("Error fetching business card:", error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchBusinessCard();
  }, [id]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        padding="16px"
        bgcolor="#f8f8f8"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!businessCard) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        padding="16px"
        bgcolor="#f8f8f8"
      >
        <Typography variant="h6">Business card not found.</Typography>
      </Box>
    );
  }

  const handleAddToContacts = () => {
    const vCardContent = `BEGIN:VCARD
VERSION:3.0
FN:${businessCard.name}
ORG:${businessCard.company}
TITLE:${businessCard.position}
TEL;TYPE=CELL:${businessCard.phone}
EMAIL:${businessCard.email}
END:VCARD`;
    const blob = new Blob([vCardContent], { type: "text/vcard" });
    const url = window.URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = `${businessCard.name}.vcf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Render the selected layout
  return (
    <Box sx={{ bgcolor: companyConfig.backgroundColor }}>
      <Layout
        businessCard={businessCard}
        companyConfig={companyConfig}
        handleAddToContacts={handleAddToContacts}
      />
    </Box>
  );
};

export default BusinessCardDisplay;
