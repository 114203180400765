import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  TextField,
  Snackbar,
  Button,
  CircularProgress,
  Skeleton,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import {
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Download as DownloadIcon,
} from "@mui/icons-material";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../configs/firebaseConfig"; // Firestore configuration
import { QRCodeCanvas } from "qrcode.react"; // For generating QR codes
import EditBusinessCardDialog from "./editBusinessCardDialog";
import DeleteBusinessCardDialog from "./deleteBusinessCardDialog";

const BusinessCardList = forwardRef(({ companyName }, ref) => {
  const [businessCards, setBusinessCards] = useState([]);
  const [filteredCards, setFilteredCards] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // For pagination
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCard, setSelectedCard] = useState(null);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const qrCodeRefs = useRef({}); // Store individual refs for each QR code

  const debounceTimeout = useRef(null);
  const observer = useRef(); // For infinite scroll

  const PAGE_SIZE = 6;

  // Fetch initial batch of business cards
  const fetchBusinessCards = async () => {
    try {
      setLoading(true); // Show loading indicator
      const q = query(
        collection(db, "businessCards"),
        where("company", "==", companyName), // Filter by company
        orderBy("name"),
        limit(PAGE_SIZE)
      );
      const querySnapshot = await getDocs(q);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc); // Track the last document for pagination

      const cards = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setBusinessCards(cards);
      setFilteredCards(cards);
      setHasMore(querySnapshot.docs.length === PAGE_SIZE); // Determine if there are more cards to load
    } catch (err) {
      console.error("Error fetching business cards:", err.message);
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };

  const fetchMoreBusinessCards = async () => {
    if (loadingMore || !hasMore) return; // Prevent multiple fetches
    setLoadingMore(true);

    try {
      const q = query(
        collection(db, "businessCards"),
        orderBy("name"),
        startAfter(lastVisible), // Use the last visible document for pagination
        limit(PAGE_SIZE)
      );
      const querySnapshot = await getDocs(q);
      const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
      setLastVisible(lastDoc); // Update the last visible document

      const newCards = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFilteredCards((prevCards) => [...prevCards, ...newCards]);
      setHasMore(querySnapshot.docs.length === PAGE_SIZE); // Check if more cards are available
    } catch (err) {
      console.error("Error fetching more business cards:", err.message);
    } finally {
      setLoadingMore(false); // Hide loading indicator
    }
  };

  const lastCardRef = (node) => {
    if (loadingMore) return; // Prevent triggering while loading more cards
    if (observer.current) observer.current.disconnect(); // Disconnect previous observer

    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        fetchMoreBusinessCards(); // Fetch the next batch of cards
      }
    });

    if (node) observer.current.observe(node); // Observe the last card
  };

  useImperativeHandle(ref, () => ({
    fetchBusinessCards,
  }));

  useEffect(() => {
    fetchBusinessCards();
  }, [companyName]);

  // Open the menu
  const handleMenuOpen = (event, card) => {
    setAnchorEl(event.currentTarget);
    setSelectedCard(card);
  };

  // Close the menu
  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedCard(null);
  };

  // Handle download of QR code
  const handleDownloadQRCode = (cardId) => {
    const qrCodeCanvas = qrCodeRefs.current[cardId];

    if (!qrCodeCanvas) {
      console.error("QR code canvas not found");
      return;
    }

    const qrCodeDataURL = qrCodeCanvas.toDataURL("image/png");

    // Create a temporary link element to trigger download
    const link = document.createElement("a");
    link.href = qrCodeDataURL;
    link.download = `${selectedCard.name}-qrcode.png`;
    link.click();
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }

    debounceTimeout.current = setTimeout(() => {
      const filtered = businessCards.filter(
        (card) =>
          card.name.toLowerCase().includes(value.toLowerCase()) ||
          card.position.toLowerCase().includes(value.toLowerCase()) ||
          card.email.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredCards(filtered);
    }, 300); // Adjust debounce delay as needed
  };

  const handleEdit = (card) => {
    setSelectedCard(card);
    setOpenEditDialog(true);
  };

  const handleDelete = (cardId) => {
    setSelectedCard({ id: cardId });
    setOpenDeleteDialog(true);
  };

  const handleEditClose = (success) => {
    setOpenEditDialog(false);
    if (success) {
      setSnackbarMessage("Business card updated successfully");
    }
  };

  const handleDeleteClose = (success) => {
    setOpenDeleteDialog(false);
    if (success) {
      setSnackbarMessage("Business card deleted successfully");
      setFilteredCards(
        filteredCards.filter((card) => card.id !== selectedCard.id)
      ); // Update filtered list
    }
  };

  return (
    <Box>
      {/* Search Bar */}
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={handleSearch}
        sx={{ mb: 4 }}
      />

      {/* Display Loading Skeletons While Fetching Data */}
      {loading ? (
        <Grid container spacing={3}>
          {[...Array(6)].map((_, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Skeleton variant="rectangular" width="100%" height={200} />
              <Skeleton variant="text" />
              <Skeleton variant="text" />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Grid container spacing={3}>
          {filteredCards.length > 0 ? (
            filteredCards.map((card, index) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                key={card.id}
                ref={index === filteredCards.length - 1 ? lastCardRef : null}
              >
                <Card sx={{ position: "relative" }}>
                  {/* More actions button */}
                  <IconButton
                    sx={{ position: "absolute", top: 0, right: 0 }}
                    onClick={(event) => handleMenuOpen(event, card)}
                  >
                    <MoreVertIcon />
                  </IconButton>

                  {card.profilePicURL && (
                    <CardMedia
                      component="img"
                      height="140"
                      image={card.profilePicURL}
                      alt={card.name}
                    />
                  )}
                  <CardContent>
                    <Typography variant="h6">{card.name}</Typography>
                    <Typography variant="body2" color="text.secondary">
                      {card.position} at {card.company}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Phone: {card.phone}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      Email: {card.email}
                    </Typography>

                    <Box sx={{ mt: 2 }}>
                      <QRCodeCanvas
                        value={`https://businesscardsbw.web.app/${card.company.toLowerCase()}/business-card/${
                          card.id
                        }`}
                        size={128}
                        ref={(el) => {
                          if (el) {
                            qrCodeRefs.current[card.id] = el; // Set the ref when the QR code renders
                          }
                        }}
                      />
                    </Box>
                  </CardContent>

                  {/* Menu for actions (Edit, Delete, Download) */}
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleEdit(selectedCard)}>
                      <EditIcon sx={{ mr: 1 }} /> Edit
                    </MenuItem>
                    <MenuItem onClick={() => handleDelete(selectedCard.id)}>
                      <DeleteIcon sx={{ mr: 1 }} /> Delete
                    </MenuItem>
                    <MenuItem
                      onClick={() => handleDownloadQRCode(selectedCard.id)}
                    >
                      <DownloadIcon sx={{ mr: 1 }} /> Download QR Code
                    </MenuItem>
                  </Menu>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography>No business cards found.</Typography>
          )}
        </Grid>
      )}

      {loadingMore && (
        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          <CircularProgress />
        </Box>
      )}

      {/* Edit Dialog */}
      {selectedCard && (
        <EditBusinessCardDialog
          open={openEditDialog}
          onClose={handleEditClose}
          card={selectedCard}
          onUpdate={(updatedCard) => {
            setBusinessCards((prevCards) =>
              prevCards.map((card) =>
                card.id === updatedCard.id ? updatedCard : card
              )
            );
            setSelectedCard(updatedCard);
          }}
        />
      )}

      {/* Delete Dialog */}
      {selectedCard && (
        <DeleteBusinessCardDialog
          open={openDeleteDialog}
          onClose={handleDeleteClose}
          cardId={selectedCard.id}
        />
      )}

      {/* Snackbar for notifications */}
      <Snackbar
        open={!!snackbarMessage}
        autoHideDuration={3000}
        onClose={() => setSnackbarMessage("")}
        message={snackbarMessage}
      />
    </Box>
  );
});

export default BusinessCardList;
