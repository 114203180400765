import { createTheme } from "@mui/material/styles";

// Function to create a theme dynamically based on the company
export const getCompanyTheme = (company) => {
  return createTheme({
    palette: {
      primary: {
        main: company.primaryColor || "#4CAF50", // Company primary color or default green
      },
      secondary: {
        main: company.secondaryColor || "#FFC107", // Company secondary color or default amber
      },
      background: {
        default: company.backgroundColor || "#FFFFFF", // Company-specific background or default white
        paper: company.paperBackground || "#FFFFFF", // Company-specific paper background
      },
      text: {
        primary: company.textColor || "#212121", // Company-specific text color or default dark
        secondary: company.secondaryTextColor || "#757575", // Company-specific secondary text
      },
    },
    typography: {
      fontFamily: company.fontFamily || "Arial, sans-serif", // Company-specific font
      h1: {
        fontWeight: 700,
        fontSize: "2.5rem",
        color: company.primaryColor || "#4CAF50",
      },
      h2: {
        fontWeight: 600,
        color: company.primaryColor || "#4CAF50",
      },
      h3: {
        fontWeight: 600,
        color: company.primaryColor || "#4CAF50",
      },
      h4: {
        fontWeight: 600,
        color: company.primaryColor || "#4CAF50",
      },
      button: {
        textTransform: "none",
        fontWeight: "bold",
        color: company.buttonTextColor || "#FFFFFF", // Button text color
      },
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: company.buttonBorderRadius || "20px", // Button styling
            padding: "10px 20px",
            backgroundColor: company.primaryColor || "#4CAF50",
            "&:hover": {
              backgroundColor: company.primaryHoverColor || "#388E3C",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            borderRadius: "10px",
            backgroundColor: company.inputBackground || "#f0f0f0", // Light input background
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow
          },
        },
      },
    },
  });
};
