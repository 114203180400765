import React, { useState, useRef } from "react";
import {
  Typography,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { signOut } from "firebase/auth";
import { auth } from "../../configs/firebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import BusinessCardList from "./businessCardList";
import BusinessCardForm from "./businessCardForm";
import companies from "../../configs/companies";

const Dashboard = () => {
  const navigate = useNavigate();
  const { companyName } = useParams(); // Get the company name from the URL
  const [openForm, setOpenForm] = useState(false); // State to control form modal visibility
  const listRef = useRef(null); // Reference to refresh BusinessCardList

  const companyConfig = companies[companyName];

  if (!companyConfig) {
    // Redirect to a 404 or default page if the company doesn't exist
    navigate("/404", { replace: true });
    return null;
  }

  // Function to handle user logout
  const handleLogout = async () => {
    await signOut(auth);
    navigate(`/${companyName}`); // Redirect to the company's login page
  };

  // Open the form modal
  const handleOpenForm = () => {
    setOpenForm(true);
  };

  // Close the form modal
  const handleCloseForm = () => {
    setOpenForm(false);
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        // backgroundColor: companyConfig.dashboardBackground || "#f9f9f9",
        px: { xs: 2, sm: 3, md: 5 }, // Responsive padding
        pt: { xs: 3, sm: 4 }, // Top padding for the dashboard
      }}
    >
      {/* Dashboard Header */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          mb: 4,
          backgroundColor: companyConfig.headerBackground || "#2A3F54",
          py: 2,
          px: 3,
          borderRadius: "12px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)", // Subtle shadow for header
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: companyConfig.primaryTextColor || "#FFFFFF",
            fontFamily: companyConfig.fontFamily || "Arial, sans-serif",
          }}
        >
          {companyConfig.name} Admin Dashboard
        </Typography>
        <Box>
          <Button
            variant="contained"
            sx={{
              mr: 2,
              backgroundColor: companyConfig.buttonColor || "#4CAF50",
              color: companyConfig.buttonTextColor || "#FFFFFF",
              ":hover": {
                backgroundColor: companyConfig.buttonHoverColor || "#388E3C",
              },
              borderRadius: companyConfig.buttonBorderRadius || "8px",
              fontFamily: companyConfig.fontFamily || "Arial, sans-serif",
            }}
            onClick={handleOpenForm}
          >
            Add Business Card
          </Button>
          <Button
            variant="contained"
            onClick={handleLogout}
            sx={{
              backgroundColor: "#D32F2F",
              color: "#FFFFFF",
              ":hover": { backgroundColor: "#B71C1C" },
              borderRadius: "8px",
            }}
          >
            Logout
          </Button>
        </Box>
      </Box>

      {/* Business Card List */}
      <Box
        sx={{
          py: 3,
          px: { xs: 1, sm: 2 },
          borderRadius: "8px",
          backgroundColor: companyConfig.paperBackground || "#FFFFFF",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.05)", // Subtle shadow for card container
        }}
      >
        <BusinessCardList ref={listRef} companyName={companyName} />
      </Box>

      {/* Add Business Card Form Modal */}
      <Dialog
        open={openForm}
        onClose={handleCloseForm}
        maxWidth="sm"
        fullWidth
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "12px", // Rounded corners for modal
          },
        }}
      >
        <DialogTitle
          sx={{
            backgroundColor: companyConfig.primaryColor || "#4CAF50",
            color: companyConfig.buttonTextColor || "#FFFFFF",
            textAlign: "center",
            fontFamily: companyConfig.fontFamily || "Arial, sans-serif",
          }}
        >
          Add New Business Card
        </DialogTitle>
        <DialogContent>
          <BusinessCardForm
            onAddCard={() => {
              // Refresh BusinessCardList
              listRef.current?.fetchBusinessCards();
              setOpenForm(false); // Close form after adding a card
            }}
            companyName={companyName} // Pass the current company to the form
          />
        </DialogContent>
        <DialogActions
          sx={{
            justifyContent: "center",
            pb: 2,
          }}
        >
          <Button
            onClick={handleCloseForm}
            sx={{
              backgroundColor: companyConfig.secondaryColor || "#FFC107",
              color: "#000",
              ":hover": {
                backgroundColor: companyConfig.primaryColor || "#4CAF50",
                color: "#FFF",
              },
              px: 3,
              borderRadius: "8px",
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
