import React, { useState } from "react";
import { TextField, Button, Grid, Typography, Paper, Box } from "@mui/material";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../../configs/firebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import companies from "../../configs/companies"; // Import companies config

const Login = () => {
  const { companyName } = useParams();
  const companyConfig = companies[companyName] || companies.default; // Get company-specific config
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate(`/${companyName}/dashboard`);
    } catch (err) {
      setError("Invalid login credentials. Please try again.");
    }
  };

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Centered vertically
        alignItems: "center",
        p: 3,
        // backgroundColor: companyConfig.backgroundColor || "#f9f9f9",
      }}
    >
      {/* Header Section */}
      <Box sx={{ width: "100%", textAlign: "center", mb: 4 }}>
        <img
          src={companyConfig.logo}
          alt={`${companyConfig.name} Logo`}
          style={{
            maxWidth: "300px", // Responsive size
            height: "auto",
            borderRadius: "12px", // Optional styling
          }}
        />
      </Box>

      {/* Login Form */}
      <Paper
        elevation={3}
        sx={{
          width: "100%",
          maxWidth: 400,
          padding: "32px",
          borderRadius: "12px",
          backgroundColor: companyConfig.paperBackground || "#ffffff",
        }}
      >
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{
            fontWeight: "bold",
            color: companyConfig.primaryColor || "#333",
            mb: 2,
            fontFamily: companyConfig.fontFamily || "Arial, sans-serif",
          }}
        >
          Admin Login
        </Typography>
        <form onSubmit={handleLogin}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: companyConfig.primaryColor || "#333",
                    },
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                type="password"
                fullWidth
                variant="outlined"
                required
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                sx={{
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: companyConfig.primaryColor || "#333",
                    },
                  },
                }}
              />
            </Grid>
            {error && (
              <Grid item xs={12}>
                <Typography color="error" align="center">
                  {error}
                </Typography>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                size="large"
                sx={{
                  backgroundColor: companyConfig.primaryColor || "#333",
                  // ":hover": {
                  //   backgroundColor: companyConfig.secondaryColor || "#555",
                  // },
                  fontWeight: "bold",
                  fontFamily: companyConfig.fontFamily || "Arial, sans-serif",
                }}
              >
                Login
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Box>
  );
};

export default Login;
