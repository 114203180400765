import React from "react";
import { Box, Typography, Avatar, Button } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ContactsIcon from "@mui/icons-material/Contacts";
import WebIcon from "@mui/icons-material/Web";

const Default = ({ businessCard, companyConfig, handleAddToContacts }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        padding: "16px",
        backgroundColor: companyConfig.backgroundColor || "#f5f5f5",
      }}
    >
      {/* Company Logo */}
      <Box
        sx={{
          textAlign: "center",
          marginBottom: "40px",
          marginTop: "16px",
        }}
      >
        <img
          src={companyConfig.logo}
          alt={`${companyConfig.name} Logo`}
          style={{
            maxWidth: "80%", // Increased to 80% width
            height: "auto",
          }}
        />
      </Box>

      {/* Profile Section */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          backgroundColor: companyConfig.primaryColor || "#0c2042",
          padding: "32px",
          textAlign: "center",
          borderRadius: "16px",
          position: "relative",
          marginTop: "32px", // Add space between logo and profile section
        }}
      >
        <Avatar
          alt={businessCard.name}
          src={businessCard.profilePicURL}
          sx={{
            width: 200, // Increased to 200px
            height: 200, // Increased to 200px
            marginBottom: "-60px",
            border: `5px solid ${companyConfig.paperBackground || "#fff"}`,
            position: "absolute",
            top: "-100px", // Adjusted for larger size
            left: "50%",
            transform: "translateX(-50%)",
          }}
        />

        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            color: "#fff",
            marginTop: "100px", // Adjusted spacing for larger avatar
            marginBottom: "8px",
            fontSize: "1.5rem",
          }}
        >
          {businessCard.name}
        </Typography>

        <Typography
          variant="subtitle1"
          sx={{
            color: "rgba(255, 255, 255, 0.8)",
            marginBottom: "16px",
          }}
        >
          {businessCard.position || "Professional"}
        </Typography>

        {/* Bio Section */}
        <Typography
          variant="body1"
          sx={{
            color: "rgba(255, 255, 255, 0.9)",
            marginBottom: "24px",
            lineHeight: 1.6,
          }}
        >
          {businessCard.bio ||
            `This professional excels at their craft and plays a vital role at ${businessCard.company}.`}
        </Typography>
      </Box>

      {/* Action Buttons */}
      <Box
        sx={{
          width: "100%",
          maxWidth: "400px",
          marginTop: "16px",
        }}
      >
        <Button
          variant="contained"
          fullWidth
          startIcon={<PhoneIcon />}
          sx={{
            marginBottom: "16px",
            backgroundColor: companyConfig.primaryColor || "#E6B566",
            ":hover": {
              backgroundColor: companyConfig.secondaryColor || "#D4A154",
            },
            fontWeight: "bold",
            borderRadius: "12px",
          }}
          href={`tel:${businessCard.phone}`}
        >
          Call {businessCard.name.split(" ")[0]}
        </Button>

        <Button
          variant="contained"
          fullWidth
          startIcon={<EmailIcon />}
          sx={{
            marginBottom: "16px",
            backgroundColor: companyConfig.primaryColor || "#E6B566",
            ":hover": {
              backgroundColor: companyConfig.secondaryColor || "#D4A154",
            },
            fontWeight: "bold",
            borderRadius: "12px",
          }}
          href={`mailto:${businessCard.email}`}
        >
          Email {businessCard.name.split(" ")[0]}
        </Button>

        <Button
          variant="contained"
          fullWidth
          startIcon={<WebIcon />}
          sx={{
            marginBottom: "16px",
            backgroundColor: companyConfig.primaryColor || "#E6B566",
            ":hover": {
              backgroundColor: companyConfig.secondaryColor || "#D4A154",
            },
            fontWeight: "bold",
            borderRadius: "12px",
          }}
          href={companyConfig.website || "#"}
          target="_blank"
        >
          Visit Website
        </Button>

        <Button
          variant="outlined"
          fullWidth
          startIcon={<ContactsIcon />}
          sx={{
            borderColor: companyConfig.primaryColor || "#E6B566",
            color: companyConfig.primaryColor || "#E6B566",
            ":hover": {
              borderColor: companyConfig.secondaryColor || "#D4A154",
              color: companyConfig.secondaryColor || "#D4A154",
            },
            fontWeight: "bold",
            borderRadius: "12px",
          }}
          onClick={handleAddToContacts}
        >
          Add to Contacts
        </Button>
      </Box>
    </Box>
  );
};

export default Default;
