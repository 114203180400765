import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  Avatar,
} from "@mui/material";
import { doc, updateDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage"; // For handling file uploads
import { db, storage } from "../../configs/firebaseConfig"; // Firebase services

const EditBusinessCardDialog = ({ open, onClose, card, onUpdate }) => {
  const [formData, setFormData] = useState(card);
  const [loading, setLoading] = useState(false);
  const [profilePicFile, setProfilePicFile] = useState(null); // For storing new profile picture

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle profile picture upload
  const handleProfilePicChange = (e) => {
    setProfilePicFile(e.target.files[0]); // Store the selected file
  };

  // Save the updated card details, including uploading a new profile picture if changed
  const handleSave = async () => {
    setLoading(true);

    try {
      let profilePicURL = formData.profilePicURL; // Keep existing picture by default

      // If a new profile picture has been uploaded, upload it to Firebase Storage
      if (profilePicFile) {
        const storageRef = ref(storage, `profilePics/${profilePicFile.name}`);
        const snapshot = await uploadBytes(storageRef, profilePicFile);
        profilePicURL = await getDownloadURL(snapshot.ref); // Get the new URL
      }

      // Update the business card data in Firestore
      const cardRef = doc(db, "businessCards", card.id);
      const updatedCard = {
        ...formData,
        profilePicURL, // Update with new or existing profile picture URL
        company: formData.company || card.company, // Retain existing company if not modified
      };

      await updateDoc(cardRef, updatedCard);

      // Call the onUpdate callback to refresh parent state with updated card data
      onUpdate(updatedCard);

      onClose(true); // Pass "true" to indicate success
    } catch (error) {
      console.error("Error updating business card:", error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)}>
      <DialogTitle>Edit Business Card</DialogTitle>
      <DialogContent>
        {/* Profile Picture and Upload */}
        <Avatar
          src={formData.profilePicURL}
          alt={formData.name}
          sx={{ width: 100, height: 100, mb: 2 }}
        />
        <Button variant="contained" component="label">
          Change Profile Picture
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={handleProfilePicChange}
          />
        </Button>

        <TextField
          label="Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          fullWidth
          sx={{ mt: 2, mb: 2 }}
        />
        <TextField
          label="Position"
          name="position"
          value={formData.position}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Bio"
          name="bio"
          value={formData.bio}
          onChange={handleChange}
          fullWidth
          multiline
          rows={3}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={handleSave}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          {loading ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditBusinessCardDialog;
